import * as S from './styled';

export const PrivacyPolicy = () => (
  <S.PageWrapper>
    <h1>BrainBloom: Brain training Games</h1>
    <section className={'no-counter'}>
      <h1>Privacy Policy</h1>
      <p>Welcome to APPLYFT, LTD. ("Applyft", "we", "us" and/or "our", BrainBloom, BrainBloom: Brain training Games). This privacy policy ("Privacy Policy") has been created to provide information about our company and our mobile game applications (together with the Site (as defined below), the "Service" or "Services") to our users ("you", "your"). The Services are provided by Applyft, LTD. ("Applyft", "we", "us" and/or "our", BrainBloom, BrainBloom: Brain training Games) located at Parnithos, 9 Flat/Office A, Germasogeia, 4040, Limassol, Cyprus. This Privacy Policy sets forth Applyft’s policy with respect to Personal Data (as defined below) that is collected from visitors to our website located at brainbloom.me (the "Site") and users of the Services. Your access to and use of the Services is subject to the Terms of Service.</p>
      <p>To utilize our services, we require you to provide personal information, such as your name and email. Additionally, we automatically gather data from your device including language preferences, IP address, time zone, device type and model, operating system, Internet service provider, mobile carrier, hardware ID, and other unique identifiers (such as IDFA and AAID). This information is crucial for delivering our services, analyzing usage patterns, and personalizing advertisements.</p>
      <p>We partner with third-party to improve our service and we use third party solution. We may process data use services by Facebook, Google, Appsflyer, Amplitude, Firebase, Apple, Amazon, Ironsource, Applovin, Tableau, UserTesting, for analytics and ad targeting, leading to data processing and storage on their servers. This collaboration helps us understand user engagement, tailor advertisements effectively, and improve the overall quality of our offerings by focusing on features and content that users value most.</p>
      <p>For an in-depth understanding of our data practices, please consult our Privacy Policy. It outlines how we handle data, your rights regarding data privacy, the identity of the data controller, and guidance on managing personalized advertising. Should you have any unresolved questions, we encourage you to reach out to us directly to e-mail: <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a></p>
      <p>By engaging with our Services, you affirm that (I) you have fully read, understood, and consent to this Privacy Policy; and (II) you are at least 16 years old or have had a parent or guardian agree to this policy on your behalf. Should you disagree or be unable to make this commitment, you must refrain from using the Services. You should then proceed to delete your account via the App or Website settings, cancel any subscriptions according to your method of subscription, and remove the App from your devices.</p>
      <p>"GDPR" refers to the General Data Protection Regulation (EU) 2016/679, established by the European Parliament and Council on April 27, 2016, focusing on the privacy and free movement of personal data of individuals.</p>
      <p>"EEA" encompasses all member states of the European Union and the European Economic Area, including the United Kingdom of Great Britain and Northern Ireland, for the context of this policy.</p>
      <p>"Process," regarding personal data, encompasses the actions of collecting, storing, and disclosing such data to third parties.</p>
      <h3>Types of Personal Data Collected</h3>
      <p>We accumulate a variety of personal data, which encompasses information you voluntarily disclose (such as your name and email), data obtained from third-party sources (for instance, logins through Google), and information collected automatically (like your IP address), with third-party services facilitating this automatic data collection.</p>
      <h3>Information You Provide</h3>
      <p>This section covers the personal information you directly provide to us when you sign up for or engage with our Services. This typically includes, but is not limited to, details like your name and email address.</p>
      <h3>Information Received from Third Parties</h3>
      <p>This refers to the data about you that we obtain from external sources. Choosing to log in via Google grants us access to certain personal details from your Google account, such as your email address, name, profile picture, and Google ID. You have the option to withdraw this access through the <a href={'https://myaccount.google.com/permissions'} target={'_blank'} rel={'noreferrer'}>Apps Permissions page</a>. For detailed information on Google's data processing practices, please refer to its <a href={'https://policies.google.com/privacy'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>.</p>
    </section>
    <section>
      <h2>Categories of personal data we collect</h2>
      <p>We collect data you give us voluntarily (for example, when you enter info about yourself, your name or email). We also may receive data about you from third parties (for example, when you sign in via Google). We also collect data automatically (for example, your IP address) and use third-party service providers for such collection.</p>
      <ol>
        <li><b>Data you give us</b>
          <p>You provide us information about yourself when you register for and/or use the Services. For example, your name or email.</p>
        </li>
        <li><b>Data provided to us by third parties</b>
          <p>When you decide to log in using Google, we get personal data from your Google account. This includes your email, name, profile image, and Google ID. You can revoke access provided to us on <a href={'https://myaccount.google.com/permissions'} target={'_blank'} rel={'noreferrer'}>Apps Permissions</a> page. To know more about how Google processes your data, visit its <a href={'https://policies.google.com/privacy'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>.</p>
          <p>When you use Sign In with Apple to register an account in App, we get personal data from your Apple ID account. This data may include, in particular, your name and verified email address. You may choose to share your real email address or an anonymous one that uses the private email relay service. Apple will show you their detailed privacy information on the Sign In with Apple screen. Apple lets you revoke access provided to App in your Apple ID settings by following the next <a href={'https://support.apple.com/en-us/102571'} target={'_blank'} rel={'noreferrer'}>steps</a>. Find more about Sign In with Apple <a href={'https://www.apple.com/legal/privacy/data/en/apple-id/'} target={'_blank'} rel={'noreferrer'}>here</a>.</p>
          <p>When you decide to log in using Facebook, we get personal data from your Facebook account. This includes your profile image, name, and Facebook ID. Unless you opt out on the Facebook login screen, we will also collect other data, such as email address. For more information, please refer to the <a href={'https://developers.facebook.com/docs/permissions/'} target={'_blank'} rel={'noreferrer'}>Facebook Permissions Reference</a> (describes the categories of information, which Facebook may share with third parties and the set of requirements) and to the <a href={'https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0&refsrc=deprecated&_rdr'} target={'_blank'} rel={'noreferrer'}>Facebook Privacy Policy</a>. In addition, Facebook lets you control the choices you made when connecting your Facebook profile on their <a href={'https://www.facebook.com/settings?tab=applications'} target={'_blank'} rel={'noreferrer'}>Apps and Websites page</a>.</p>
        </li>
        <li><b>Data we collect automatically:</b>
          <ul>
            <li>
              Data about how you found us
              <p>We collect data about your referring app or URL (that is, the app or place on the Web where you were when you tapped on our ad).</p>
            </li>
            <li>
              Device and Location data
              <p>We collect data from your device. Examples of such data include: language settings, IP address, time zone, type and model of a device, device settings, operating system and its version, Internet service provider, mobile carrier, hardware ID, and Facebook ID.</p>
            </li>
            <li>
              Usage data
              <p>We record how you interact with our Services. For example, we log your taps/clicks on certain areas of the interface, the features, and content you interact with, how often you use the Services, how long you are in the Services, and your subscription orders. We also record the ads in our App or Website with which you interact (and the Internet links to which those adds lead).</p>
            </li>
            <li>
              Advertising IDs
              <p>We collect your Apple Identifier for Advertising (“IDFA”) or Google Advertising ID (“AAID”) (depending on the operating system of your device). You can typically reset these numbers through the settings of your device’s operating system (but we do not control this).</p>
            </li>
            <li>
              Transaction data
              <p>When you make payments through the Services, you need to provide financial account data, such as your credit card number, to our third-party service providers. We do not collect or store full credit card number data, though we may receive credit card-related data, data about the transaction, including: date, time and amount of the transaction, the type of payment method used.</p>
            </li>
            <li>
              Cookies
              <p>A cookie is a small text file that is stored on a user's computer for record-keeping purposes. Cookies can be either session cookies or persistent cookies. A session cookie expires when you close your browser and is used to make it easier for you to navigate our Services. A persistent cookie remains on your hard drive for an extended period of time. We also use tracking pixels that set cookies to assist with delivering online advertising.</p>
              <p>Cookies are used, in particular, to automatically recognize you the next time you visit our website. As a result, the information, which you have earlier entered in certain fields on the website may automatically appear the next time when you use our Services. Cookie data will be stored on your device and most of the times only for a limited time period.</p>
            </li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h2>For what purposes we process your personal data</h2>
      <p>We process your personal data:</p>
      <ol>
        <li>
          To provide our Services
          <p>This includes enabling you to use the Services in a seamless manner and preventing or addressing Services errors or technical issues. To host personal data and enable our Services to operate and be distributed we use cloud platforms for on-demand deployment, related hosting and backend services: Amazon Web Services (<a href={'https://aws.amazon.com/privacy/'} target={'_blank'} rel={'noreferrer'}>Privacy Notice</a>).</p>
          <p>We use Firebase Performance Monitoring, Firebase Crash Reporting and Crashlytics, which are monitoring services provided by Google. To learn more, please visit <a href={'https://policies.google.com/privacy'} target={'_blank'} rel={'noreferrer'}>Google’s Privacy Policy</a>, <a href={'https://firebase.google.com/support/privacy/'} target={'_blank'} rel={'noreferrer'}>Privacy and Security in Firebase</a> and <a href={'https://firebase.google.com/terms/crashlytics-app-distribution-data-processing-terms'} target={'_blank'} rel={'noreferrer'}>Crashlytics’ Data Collection Policy</a>. CookieYes helps us to customize cookie banner, record you consent and manage other cookie compliance needs. You may find more info about your data processing by CookieYes in the <a href={'https://www.cookieyes.com/dpa/'} target={'_blank'} rel={'noreferrer'}>Data Processing Agreement</a>.</p>
        </li>
        <li>
          To customize your experience
          <p>We process your personal data to adjust the content of the Services and make offers tailored to your personal preferences. As a result of such processing, we will show in App more puzzle games from, for example, “Memory” category.</p>
        </li>
        <li>
          To manage your account
          <p>We process your personal data to secure your accessing personal account and send you technical notifications or emails about the performance of our Services, security, payment transactions, notices regarding our <a href={'/terms'}>Terms and Conditions of Use</a> or this Privacy Policy.</p>
        </li>
        <li>
          To communicate with you regarding your use of our Services
          <p>We communicate with you, for example, by push notifications or by emails. These may include reminders and motivational messages encouraging you to continue puzzle game, or other information about the Services. As a result, you may, for example, receive a push notification every day at a particular time reminding you about daily puzzle streak. To opt out of receiving push notifications, you need to change the settings on your device. To opt-out of receiving emails, you should click unsubscribe link in the footer of our email.</p>
          <p>The services that we use for these purposes may collect data concerning the date and time when the message was viewed by our App’s users, as well as when they interacted with it, such as by clicking on links included in the message.</p>
        </li>
        <li>
          To provide you with customer support
          <p>We process your personal data to respond to your requests for technical support. We use Gmail email service to handle customer inquiries. When you send us inquiries via contact form or via email, we will store the details provided by you via Gmail. To know more about how Google processes your data, visit its <a href={'https://policies.google.com/privacy'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>.</p>
        </li>
        <li>
          To research and analyze your use of the Services
          <p>This helps us to better understand our business, analyze our operations, maintain, improve, innovate, plan, design, and develop the Services and our new products. We also use such data for statistical analysis purposes, to test and improve our offers. his enables us to better understand what features and sections of App our users like more, what categories of users use our App. As a consequence, we often decide how to improve App based on the results obtained from this processing. For example, if we discover that users are more interested in training games for memory improvement, we may focus on developing more exercises for memory in App.</p>
          <p>To perform research and analysis about how users interact with our Services we use Appsflyer. Appsflyer enables us to understand, in particular, how users find us (for example, who was the advertiser that delivered an ad to users, which led you to our Services). Appsflyer also provides us with different analytics tools that enable us to research and analyze use of the Services – <a href={'https://www.appsflyer.com/legal/services-privacy-policy/'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>, <a href={'https://www.appsflyer.com/optout'} target={'_blank'} rel={'noreferrer'}>Opt Out</a>. We use Facebook Analytics, which is a service provided by Facebook that allows us to use different analytical tools. On Facebook Analytics we get, in particular, aggregated demographics and insights on how many people access the Services, how often users make purchases, and other users’ interactions within the Services – <a href={'https://www.facebook.com/about/privacy/'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>.</p>
          <p>To analyse how visitors use our Services and to measure effectiveness of some ads we use Google Analytics, a web analysis program of Google. In order to provide us with analytics, Google Analytics places cookies on your device. On Google Analytics we get, in particular, aggregated information on the data you enter on our Services and users’ interactions within the Services. Google allows you to influence the collection and processing of information generated by the Google, in particular, by installing a browser plug-in, available <a href={'https://tools.google.com/dlpage/gaoptout/'} target={'_blank'} rel={'noreferrer'}>here</a>.</p>
          <p>We also use Amplitude, which is an analytics service provided by Amplitude Inc. We use this tool to understand how customers use our Services. Amplitude collects various technical information, in particular, time zone, type of device (phone or tablet), unique identifiers (such as IDFA). Amplitude also allows us to track various interactions (entering your goals) that occur on App or the Website. As a result, Amplitude helps us to decide what features should we focus on. Amplitude provides more information on how they process data in its <a href={'https://amplitude.com/privacy'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>.</p>
          <p>To track and analyze behavior of our Services’ users (in particular, how they react to changes of App’s or Website’s structure, text or any other component), we use Firebase Remote Config. Firebase Remote Config is an A/B testing and configuration service provided by Google – <a href={'https://firebase.google.com/terms/data-processing-terms/'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a> and <a href={'https://firebase.google.com/support/privacy'} target={'_blank'} rel={'noreferrer'}>Privacy and Security in Firebase</a>.</p>
          <p>We also use Firebase Analytics, which is an analytics service provided by Google. In order to understand Google's use of data, consult Google's <a href={'https://policies.google.com/technologies/partner-sites?hl=ru'} target={'_blank'} rel={'noreferrer'}>Partner Policy</a> (see also, <a href={'https://firebase.google.com/support/privacy/'} target={'_blank'} rel={'noreferrer'}>Firebase Privacy information</a> and <a href={'https://policies.google.com/privacy'} target={'_blank'} rel={'noreferrer'}>Google’s Privacy Policy</a>).</p>
          <p>To perform our Website analysis, we also use Tableau, a company providing interactive data visualization products focused on business intelligence – <a href={'https://www.salesforce.com/company/privacy/'} target={'_blank'} rel={'noreferrer'}>Tableau Privacy Policy</a>.</p>
        </li>
        <li>
          To send you marketing communications
          <p>We process your personal data for our marketing campaigns. We may add your email address to our marketing list. As a result, you will receive information about our products, such as for example, special offers. If you do not want to receive marketing emails from us, you can unsubscribe following instructions in the footer of the marketing emails. We may send you push notifications for marketing purposes. To opt out of receiving push notifications, you need to change the settings on your device.</p>
        </li>
        <li>
          To personalize our ads
          <p>We and our partners, including Facebook and Google, use your personal data to tailor ads and possibly even show them to you at the relevant time. For example, if you have installed our App, you might see ads of our products, for example, in your Facebook’s feed.</p>
          <p>How to opt out or influence personalized advertising iOS: On your iPhone or iPad, go to Settings > Privacy & Security > Apple Advertising and deselect Personalized Ads.</p>
          <p>Android: To opt-out of ads on an Android device, go to Settings > Privacy > Ads and enable Opt out of Ads personalization. In addition, you can reset your advertising identifier in the same section (this also may help you to see less of personalized ads).</p>
          <p>macOS: On your MacBook, you can disable personalized ads: go to System Preferences > Security & Privacy > Privacy, select Apple Advertising, and deselect Personalized Ads.</p>
          <p>Windows: On your laptop running Windows 10, you shall select Start > Settings > Privacy and then turn off the setting for Let apps use advertising ID to make ads more interesting to you based on your app activity. If you have other Windows version, please follow the steps <a href={'https://account.microsoft.com/privacy/ad-settings/signedout'} target={'_blank'} rel={'noreferrer'}>here</a>.</p>
          <p>Browsers: It is also may be possible to stop your browser from accepting cookies altogether by changing your browser’s cookie settings. You can usually find these settings in the “options” or “preferences” menu of your browser. The following links may be helpful, or you can use the “Help” option in your browser.</p>
          <p>
            Cookie settings in <a href={'http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies#ie=ie-10'} target={'_blank'} rel={'noreferrer'}>Internet Explorer</a><br/>
            Cookie settings in <a href={'http://support.mozilla.com/en-US/kb/Cookies'} target={'_blank'} rel={'noreferrer'}>Firefox</a><br/>
            Cookie settings in <a href={'https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666'} target={'_blank'} rel={'noreferrer'}>Chrome</a><br/>
            Cookie settings in Safari (<a href={'https://support.apple.com/kb/PH17191?locale=en_US'} target={'_blank'} rel={'noreferrer'}>web</a> and <a href={'http://support.apple.com/kb/HT1677'} target={'_blank'} rel={'noreferrer'}>iOS</a>)
          </p>
          <p>To learn even more about how to affect advertising choices on various devices, please look at the information available <a href={'http://www.networkadvertising.org/mobile-choice'} target={'_blank'} rel={'noreferrer'}>here</a>.</p>
          <p>Google allows its users to <a href={'https://adssettings.google.com/authenticated'} target={'_blank'} rel={'noreferrer'}>opt out of Google’s personalized ads</a> and <a href={'https://tools.google.com/dlpage/gaoptout/'} target={'_blank'} rel={'noreferrer'}>to prevent their data from being used</a> by Google Analytics.</p>
          <p>We value your right to influence the ads that you see, thus we are letting you know what service providers we use for this purpose and how some of them allow you to control your ad preferences.</p>
          <p>We use Facebook pixel on the Services. Facebook pixel is a code placed on the Services collecting data that helps us track conversions from Facebook Ads, build targeted audience and remarket to people who have taken some actions on the Services (for example, made a purchase).</p>
          <p>We use Facebook Ads Manager together with Facebook Custom Audience, which allows us to choose audiences that will see our ads on Facebook or other Facebook’s products (for example, Instagram). Through Facebook Custom Audience we may create a list of users with certain sets of data, such as an IDFA, choose users that have completed certain actions in App (for example, installed it). As a result, we may ask Facebook to show some ads to a particular list of users. As a result, more of our ads may show up while you are using Facebook or other Facebook’s products (for example, Instagram). You may learn how to opt out of advertising provided to you through Facebook Custom Audience <a href={'https://www.facebook.com/business/help/1415256572060999'} target={'_blank'} rel={'noreferrer'}>here</a>.</p>
          <p>Facebook also allows its users to influence the types of ads they see on Facebook. To find how to control the ads you see on Facebook, please go <a href={'https://www.facebook.com/help/146952742043748?helpref=related'} target={'_blank'} rel={'noreferrer'}>here</a> or adjust your <a href={'https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen'} target={'_blank'} rel={'noreferrer'}>ads settings on Facebook</a>.</p>
          <p>Google Ads is an ad delivery service provided by Google that can deliver ads to users. In particular, Google allows us to tailor the ads in a way that they will appear, for example, only to users that have conducted certain actions with our App (for example, show our ads to users who have purchased a subscription). Some other examples of events that may be used for tailoring ads include, in particular, installing our App. Google allows its users to opt out of <a href={'https://adssettings.google.com/authenticated'} target={'_blank'} rel={'noreferrer'}>Google’s personalized ads</a> and to prevent their data from being used by <a href={'https://tools.google.com/dlpage/gaoptout/'} target={'_blank'} rel={'noreferrer'}>Google Analytics</a>.</p>
          <p>We use IronSource (<a href={'https://developers.is.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>) and Applovin (<a href={'https://www.applovin.com/privacy/'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>) solutions in our app to deliver personalized ads, analyze user interactions, and support our app's monetization. They may collect device identifiers, IP addresses, and location data for these purposes. We may also share data with trusted third-party advertising partners to enhance the relevance of ads shown to you. By using our app, you consent to the collection and processing of your data for these purposes.</p>
        </li>
        <li>
          To process your payments
          <p>We provide paid products and/or services within the Services. For this purpose, we use third-party services for payment processing (for example, payment processors). As a result of this processing, you will be able to make a payment for a subscription and we will be notified that the payment has been made.</p>
          <p>We will not store or collect your payment card details ourselves. This information will be provided directly to our third-party payment processors.</p>
        </li>
        <li>
          To enforce our Terms and Conditions of Use and to prevent and combat fraud
          <p>We use personal data to enforce our agreements and contractual commitments, to detect, prevent, and combat fraud. As a result of such processing, we may share your information with others, including law enforcement agencies (in particular, if a dispute arises in connection with our <a href={'/terms'}>Terms of User</a>).</p>
        </li>
        <li>
          To comply with legal obligations
          <p>We may process, use, or share your data when the law requires it, in particular, if a law enforcement agency requests your data by available legal means.</p>
        </li>
      </ol>
    </section>
    <section>
      <h2>Under what legal bases we process your personal data (applies only to EEA based users)</h2>
      <p>In this section, we are letting you know what legal basis we use for each particular purpose of processing. For more information on a particular purpose, please refer to Section 2. This section applies only to EEA-based users.</p>
      <p>We process your personal data under the following legal bases.</p>
      <ol>
        <li>
          Your consent
          <p>Under this legal basis we will send you marketing emails. You have the right to withdraw your consent at any time by clicking on the unsubscribe link in the footer of our marketing emails.</p>
        </li>
        <li>
          Performing our contract with you
          <p>Under this legal basis we:</p>
          <ul className={'line-list'}>
            <li>Provide our Services (in accordance with our <a href={'/terms'}>Terms and Conditions of Use</a>);</li>
            <li>Customize your experience;</li>
            <li>Manage your account and provide you with customer support;</li>
            <li>Communicate with you regarding your use of our Services;</li>
            <li>Process your payments.</li>
          </ul>
        </li>
        <li>
          Legitimate interests, unless those interests are overridden by your interests or fundamental rights and freedoms that require protection of personal data
          <p>We rely on legitimate interests:</p>
          <ul className={'dot-list'}>
            <li>
              to communicate with you regarding your use of our Services
              <p>This includes, for example, sending you push notifications proposing you to try a new puzzle game. The legitimate interest we rely on for this purpose is our interest to encourage you to use our Services more often. We also take into account the potential benefits to you of puzzle games, which may help you to advance in wellbeing.</p>
            </li>
            <li>
              to research and analyze your use of the Services
              <p>Our legitimate interest for this purpose is our interest in improving our Services so that we understand users’ preferences and are able to provide you with a better experience (for example, to make the use of App or the Website easier and more enjoyable, or to introduce and test new features).</p>
            </li>
            <li>
              to send you marketing communications
              <p>The legitimate interest we rely on for this processing is our interest to promote our Services in a measured and appropriate way.</p>
            </li>
            <li>
              to personalize our ads
              <p>The legitimate interest we rely on for this processing is our interest to promote our Services in a reasonably targeted way.</p>
            </li>
            <li>
              to enforce our Terms and Conditions of Use and to prevent and combat fraud
              <p>Our legitimate interests for this purpose are enforcing our legal rights, preventing and addressing fraud and unauthorized use of the Services, and non compliance with our <a href={'/terms'}>Terms of Use</a>.</p>
            </li>
          </ul>
        </li>
        <li>Compliance with legal obligations</li>
      </ol>
    </section>
    <section>
      <h2>With whom we share your personal data</h2>
      <p>We share information with third parties that help us operate, provide, improve, integrate, customize, support, and market our Services. We may share some sets of personal data, in particular, for purposes indicated in Section 2 of this Privacy Policy. The types of third parties we share information with include, in particular:</p>
      <ol>
        <li>
          Service providers
          <p>We share personal data with third parties that we hire to provide services or perform business functions on our behalf, based on our instructions. We share your personal information with the following types of service providers:</p>
          <ul className={'line-list'}>
            <li>cloud storage providers (AWS);</li>
            <li>data analytics providers (Facebook, Google, Appsflyer, Firebase, Amplitude, Tableau);</li>
            <li>measurement partners (CookieYes);</li>
            <li>marketing partners (in particular, social media networks, marketing agencies, Facebook, Google); and</li>
            <li>payment processing providers (Solidgate, PayPal).</li>
          </ul>
        </li>
        <li>
          Law enforcement agencies and other public authorities
          <p>We may use and disclose personal data to enforce our <a href={'/terms'}>Terms and Conditions of Use</a>, to protect our rights, privacy, safety, or property, and/or that of our affiliates, you or others, and to respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities, or in other cases provided for by law.</p>
        </li>
        <li>
          Third parties as part of a merger or acquisition
          <p>As we develop our business, we may buy or sell assets or business offerings. Customers’ information is generally one of the transferred business assets in these types of transactions. We may also share such information with any affiliated entity (e.g. parent company or subsidiary) and may transfer such information in the course of a corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</p>
        </li>
      </ol>
    </section>
    <section>
      <h2>How you can exercise your privacy rights</h2>
      <p>To be in control of your personal data, you have the following rights:</p>
      <p>Accessing / reviewing / updating / correcting your personal data. You may review, edit, or change the personal data that you had previously provided on the Services.</p>
      <p>Deleting your personal data. You can request erasure of your personal data as permitted by law.</p>
      <p>When you request deletion of your personal data, we will use reasonable efforts to honor your request. In some cases, we may be legally required to keep some of the data for a certain time; in such an event, we will fulfill your request after we have complied with our obligations.</p>
      <p>Objecting to or restricting the use of your personal data. You can ask us to stop using all or some of your personal data or limit our use thereof.</p>
      <p><u>Additional information for EEA-based users:</u></p>
      <p>If you are based in the EEA, you have the following rights in addition to the above:</p>
      <p>The right to lodge a complaint with supervisory authority. We would love you to contact us directly, so we could address your concerns. Nevertheless, you have the right to lodge a complaint with a competent data protection supervisory authority, in particular in the EU Member State where you reside, work or where the alleged infringement has taken place.</p>
      <p>The right to data portability. If you wish to receive your personal data in a machine-readable format, you can send respective request to us as described below.</p>
      <p>To exercise any of your privacy rights, please send a request to <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a>.</p>
    </section>
    <section>
      <h2>Age limitation</h2>
      <p>We do not knowingly process personal data from persons under 16 years of age. If you learn that anyone younger than 16 has provided us with personal data, please contact us at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a>.</p>
    </section>
    <section>
      <h2>International data transfers</h2>
      <p>We do business globally. We may transfer personal data to countries other than the country in which the data was originally collected in order to provide the Services set forth in the <a href={'/terms'}>Terms and Conditions of Use</a> and for purposes indicated in this Privacy Policy. If these countries do not have the same data protection laws as the country in which you initially provided the information, we deploy special safeguards.</p>
      <p>In particular, if we transfer personal data originating from the EEA to countries with not adequate level of data protection, we use one of the following legal bases: (i) Standard Contractual Clauses approved by the European Commission (details available <a href={'https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en'} target={'_blank'} rel={'noreferrer'}>here</a>), or (ii) the European Commission adequacy decisions about certain countries (details available <a href={'https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en'} target={'_blank'} rel={'noreferrer'}>here</a>).</p>
    </section>
    <section>
      <h2>Changes to this Privacy Policy</h2>
      <p>We may modify this Privacy Policy from time to time. If we decide to make material changes to this Privacy Policy, you will be notified through our Services or by other available means and will have an opportunity to review the revised Privacy Policy. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised Privacy Policy.</p>
    </section>
    <section>
      <h2>Supplemental notice for California residents</h2>
      <p>This Supplemental California Privacy Notice only applies to our processing of personal information that is subject to the California Consumer Privacy Act of 2018 (the “CCPA”). The CCPA provides California residents with the right to know what categories of personal information we have collected about them and whether we disclosed that personal information for a business purpose (e.g., to a service provider) in the preceding twelve months. California residents can find this information below.</p>
      <table>
        <thead>
          <tr>
            <th>Category of Personal Information Collected</th>
            <th>Categories of Third Parties Personal Information is Disclosed to for a Business Purpose</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Identifiers</td>
            <td>Service providers Users or third parties you share with Advertising partners</td>
          </tr>
          <tr>
            <td>Personal information categories listed in Cal. Civ. Code § 1798.80(e)</td>
            <td>Service providers Users or third parties you share with</td>
          </tr>
          <tr>
            <td>Commercial information</td>
            <td>Payment solutions partners</td>
          </tr>
          <tr>
            <td>Internet or other electronic network activity</td>
            <td>Service providers Users or third parties you share with Advertising partners</td>
          </tr>
          <tr>
            <td>Inferences drawn from other personal information to create a profile about a consumer</td>
            <td>Service providers Advertising partners</td>
          </tr>
          <tr>
            <td>Geolocation data</td>
            <td>Service providers Advertising partners</td>
          </tr>
        </tbody>
      </table>
      <p>The categories of sources from which we collect personal information and our business and commercial purposes for using personal information are set forth above.</p>
      <p>“Sales” of Personal Information under the CCPA. For purposes of the CCPA, we do not “sell” personal information, nor do we have actual knowledge of any “sale” of personal information of minors under 16 years of age.</p>
      <p>Non-Discrimination. California residents have the right not to receive discriminatory treatment by us for the exercise of their rights conferred by the CCPA.</p>
      <p>Authorized Agent. Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. To designate an authorized agent, please contact us as set forth below.</p>
      <p>Verification. When you make a request, we will ask you to provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, which may include confirming the email address associated with any personal information we have about you.</p>
      <p>If you are a California resident and would like to exercise any of your rights under the CCPA, please contact us as at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a>. We will process such requests in accordance with applicable laws.</p>
    </section>
    <section>
      <h2>Supplemental notice for Virginia residents</h2>
      <p>This Supplemental Virginia Privacy Notice only applies if you reside in the Commonwealth of Virginia. Where applicable, it describes how we use and process your personal data and explains your particular rights under Virginia Consumer Data Privacy Act (“VCDPA”).</p>
      <p>Disclosures about the use of your personal data</p>
      <p>We may collect and use certain information about you, some of which may be personal data (such as your name, email address, IP address, or other information which may be reasonably linked to you), in order to operate the Services and to maximize your experience.</p>
      <p>If you would like more information about the categories of your personal data we collect or the purposes for which we collect them, please read Section 1 and Section 2. To learn more about sharing your personal data with our business partners and other third parties, please read Section&nbsp;4.</p>
      <p>Data Rights</p>
      <p>Section 5 of our Privacy Policy describes the data rights we offer to all users and how to execute these rights. This includes the right to access, review, correct, update your data, obtain a portable copy of your data, or delete data related to your stored preferences and your use of the Services. Before completing your request, we may require some information sufficient to authenticate your identity.</p>
      <p>Additionally, VCDPA provides Virginia residents with these data rights:</p>
      <p>Opt out of the Processing of your Personal Data for Targeted Advertising. In order to exercise your choice as a Virginia resident, please contact us at support@brainbloom.me with the subject line “Virginia Do Not Sell Request”. We will process such requests in accordance with applicable laws.</p>
      <p>Please note that we do not process personal data for purposes of (1) the sale of personal data, as defined by the VCDPA, or (2) profiling in furtherance of decisions that produce legal or similarly significant effects concerning the consumer.</p>
      <p>Confirm whether your Personal Data is being Processed. You may confirm whether your personal data is being processed by emailing us at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a>.</p>
      <p>Appeal a Case with regard to your Request. In the case where we declined to take action on your data rights request or have rejected your request, you may contact us at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a> to initiate an appeal of this decision. Please use the subject line “Appeal of Refusal to Take Action on Privacy Request” and provide the relevant information in the email. Once we receive your appeal, we will notify you in writing within 60 days of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.</p>
      <p>If your appeal is denied, you may contact the Office of the Virginia Attorney General by these means:</p>
      <p>
        Office of the Attorney General | Virginia.gov<br/>
        <a href={'https://www.virginia.gov/agencies/office-of-the-attorney-general/#vagov'} target={'_blank'} rel={'noreferrer'}>www.virginia.gov/agencies/office-of-the-attorney-general/#vagov</a><br/>
        202 North Ninth Street<br/>
        Richmond, VA 23219<br/>
      </p>
    </section>
    <section>
      <h2>Supplemental notice for Nevada residents</h2>
      <p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain personal information to third parties who intend to license or sell that personal information. You can exercise this right by contacting us at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a> with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with your account. Please note that we do not currently sell your personal information as sales are defined in Nevada Revised Statutes Chapter 603A.</p>
    </section>
    <section>
      <h2>Data retention</h2>
      <p>We will store your personal data for as long as it is reasonably necessary for achieving the purposes set forth in this Privacy Policy (including providing the Services to you), which includes (but is not limited to) the period during which you have an account with the Services. We will also retain and use your personal data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
    </section>
    <section>
      <h2>How “Do Not Track” requests are handled</h2>
      <p>Except as otherwise stipulated in this Privacy Policy, this App does not support “Do Not Track” requests. To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.</p>
    </section>
    <section>
      <h2>Contact us</h2>
      <p>You may contact us at any time for details regarding this Privacy Policy and its previous versions. For any questions concerning your account or your personal data please contact us at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a>.</p>
      <p>
        APPLYFT LTD, Parnithos, 9 Flat/Office A, Germasogeia, 4040, Limassol, Cyprus<br/>
        +1 (650) 319-7416<br/>
        support@brainbloom.me<br/>
      </p>
    </section>
    <p>Last Update: 13 March 2023</p>
  </S.PageWrapper>
);
