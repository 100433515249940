import { useRoutes, Navigate, BrowserRouter } from 'react-router-dom';
import './App.css';
import {
  Home,
  PrivacyPolicy,
  PrivacyPolicyFindmy,
  Terms,
  TermsFindmy,
  PaymentPolicy,
} from './pages';

const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/privacy_policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '/privacy_policy_findmy',
    element: <PrivacyPolicyFindmy />,
  },
  {
    path: '/terms_findmy',
    element: <TermsFindmy />,
  },
  {
    path: '/payment_policy',
    element: <PaymentPolicy />,
  },
  {
    path: '/*',
    element: <Navigate to="/" />,
  },
];

const RouterPath = () => {
  return useRoutes(routes);
};

function App() {
  return (
    <BrowserRouter>
      <RouterPath />
    </BrowserRouter>
  );
}

export default App;
