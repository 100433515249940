import styled from 'styled-components';

export const PageWrapper = styled.div`
  margin: 0 auto 40px;
  padding: 0 10px;
  max-width: 700px;
  counter-reset: section;
  
  *:not(h1, h2) {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }

  h1 {
    font-weight: 900;
    text-align: center;
  }

  section:not(.no-counter) {
    counter-increment: section;
    
    h2 {
      &:before {
        content: counter(section) ". "
      }
    }
    
    ul {
      margin-top: 10px;
      padding-left: 20px;
      list-style-type: lower-alpha;
      list-style-position: inside;
      
      &.line-list {
        list-style-type: none;
        
        li {
          &:before {
            content: "\\2014 ";
            margin-right: 5px;
          }
        }
      }
      
      &.dot-list {
        list-style-type: disc;
      }
    }
    
    ol {
      counter-reset: item;
      list-style-type: none;
      padding: 0;
      
      & > li {
        counter-increment: item;
        margin-top: 10px;
        
        &:before {
          content: counter(section) "." counter(item) ". ";
          font-weight: 600;
        }
      }
    }
  }
  
  table {
    border: 1px solid #000;
    border-collapse: collapse;
    
    th, td {
      border: 1px solid #000;
      padding: 10px;
    }
  }
`;
