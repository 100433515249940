import * as S from './styled';

export const PaymentPolicy = () => (
  <S.PageWrapper>
    <h1>Subscription Terms</h1>
    <section>
      <h2>Free trial</h2>
      <p>We may offer a free trial subscription for service. Unless you cancel at least 24 hours before the end of the free trial, you will be automatically charged a price indicated on the payment screen for a chosen subscription period.</p>
    </section>
    <section>
      <h2>Subscription</h2>
      <p>The subscription renews automatically at the end of each period (each week, month, 6 months, year, or otherwise, depending on the option selected by you at the time of purchase) until you cancel.</p>
    </section>
    <section>
      <h2>Payment method</h2>
      <p>Payment will be charged to the payment method you submitted at the time of purchase at confirmation of purchase. You authorize us to charge the applicable subscription fees to the payment method that you submit.</p>
    </section>
    <section>
      <h2>Cancellation</h2>
      <p>Canceling your subscription means that the automatic renewal will be disabled, but you will still have access to all your subscription features for the remaining time of your then current period. Note that deleting the app does not cancel your subscriptions.</p>
      <p>If you purchased a subscription or enabled free trial on App Store: You can cancel a free trial or a subscription anytime by turning-off auto renewal through your Apple ID account settings. To avoid being charged, cancel the subscription in your Apple ID account settings at least 24 hours before the end of the free trial or then-current subscription period. You alone can manage your subscriptions. Learn more about managing subscriptions (and how to cancel them) on <a href={'https://support.apple.com/en-us/HT202039'} target={'_blank'} rel={'noreferrer'}>Apple support page</a>.</p>
      <p>If you purchased a subscription or enabled free trial on Google Play: You can cancel a free trial or a subscription anytime by turning off auto-renewal through your Google Play account settings. To avoid being charged, cancel the subscription in your account settings at least 24 hours before the end of the free trial or then-current subscription period. You alone can manage your subscriptions. Learn more about managing subscriptions (and how to cancel them) on <a href={'https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en'} target={'_blank'} rel={'noreferrer'}>Google’s support page</a>.</p>
      <p>If you purchased a subscription or enabled free trial on our website: You can cancel a free trial or a subscription by contacting our support at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a> or by clicking on the “Cancel Subscription” button in your account on our website before the end of the free trial or then-current subscription period.</p>
    </section>
    <section>
      <h2>Changes</h2>
      <p>To the maximum extent permitted by applicable laws, we may change subscription fees at any time. We will give you reasonable notice of any such pricing changes by posting the new prices on the app and/or by sending you an email notification, or in other prominent way. If you do not wish to pay the new fees, you can cancel the applicable subscription prior to the change going into effect.</p>
    </section>
    <section>
      <h2>Refunds</h2>
      <p>If you purchased a subscription or enabled a free trial on the App Store: If you are eligible for a refund, you’ll have to request it directly from Apple. To request a refund, follow these instructions from the <a href={'https://support.apple.com/en-us/HT202039'} target={'_blank'} rel={'noreferrer'}>Apple support page</a>.</p>
      <p>If you purchased a subscription or enabled free trial on Google Play: If you are eligible for a refund, you'll have to request it directly from Google. To request a refund, follow these instructions from <a href={'https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en'} target={'_blank'} rel={'noreferrer'}>Google’s support page</a>.</p>
      <p>If you purchased a subscription or enabled free trial on our website: Please refer to our <a href={'/terms'}>Terms and Conditions of Use</a>.</p>
      <p>If you have any questions in relation to the subscription terms, please contact us at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a>.</p>
      <p>Please take a screenshot of this information for your reference. This may help you to control your subscriptions.</p>
    </section>
    <p>Last Update: 13 March 2023</p>
  </S.PageWrapper>
);
