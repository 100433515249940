import * as S from './styled';

export const Terms = () => (
  <S.PageWrapper>
    <section className={'no-counter'}>
      <h1>Terms and Conditions of Use</h1>
      <p>Please review carefully these Terms and Conditions of Use (“Terms”) before using the services offered by APPLYFT LTD with registered office at Parnithos, 9 Flat/Office A, Germasogeia, 4040, Limassol, Cyprus. (also “BrainBloom”, “we”, “us”). These Terms constitute a legally binding agreement between BrainBloom and you, which regulates your use of the website located at https://brainbloom.me/ (the “Website”) and services accessible via the BrainBloom – Brain Training Games mobile application (“App”) and related services, including all information, text, graphics, software, and our emails (the “Content”). To make these Terms easier to read, the Website, App, the Content and our services are collectively called the “Services”.</p>
      <p>This is a subscription service that automatically renews. We also aim to provide information about our <a href={'/payment-policy'}>Payment policy</a> at or near the point of purchase. Please read these Terms (in particular, Section 7 “Payments and Refunds”) and <a href={'/payment-policy'}>Payment policy</a> carefully before starting a free trial or completing a purchase for the App auto-renewing subscription services. To avoid being charged you must affirmatively cancel your subscription or a trial at least 24 hours before the end of the free trial or the current subscription period. If you are unsure how to cancel a subscription or a free trial, please visit <a href={'https://support.apple.com/en-us/HT202039'} target={'_blank'} rel={'noreferrer'}>Apple Support</a>, <a href={'https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en'} target={'_blank'} rel={'noreferrer'}>Google Play Help</a> (or any other relevant app stores support pages), or our Website depending on where you have purchased your subscription. Deleting the App does not cancel your subscriptions or free trials. You may wish to make a print screen of this information for your reference.</p>
      <p>IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. CUSTOMERS: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND BRAINBLOOM THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 13 “MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER” BELOW FOR DETAILS REGARDING ARBITRATION. FURTHERMORE, THESE TERMS CONTAIN IMPORTANT DISCLAIMERS (SECTION 2), DISCLAIMER OF WARRANTIES (SECTION 10), LIMITATION OF LIABILITY (SECTION 11) AND CLASS ACTION WAIVER (SECTION 13).</p>
    </section>
    <section>
      <h2>General Terms</h2>
      <ol>
        <li>These Terms establish a legally binding contractual relationship between you and BrainBloom. For this reason, PLEASE READ THE TERMS CAREFULLY BEFORE USING THE SERVICES.</li>
        <li>Please also review our <a href={'/privacy-policy'}>Privacy Policy</a>. The terms of the Privacy Policy and other supplemental terms, policies or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference.</li>
        <li>We may change these Terms on this page of the Website. We may provide you with notice about some critical changes, for example by email or by posting notifications on the Services, but are not obliged to do so in every case. Any other changes will be notified to you only by updating the "Last updated" date of these Terms and you waive any right to receive specific notice of each such change. If you don’t agree to the changes, you should stop using the Services. Use of the Services after any changes to these Terms are made means that you accept such changes.</li>
        <li>IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS, OR IF YOU ARE NOT ELIGIBLE OR AUTHORIZED TO BE BOUND BY THESE TERMS, THEN DO NOT DOWNLOAD THE APP OR OTHERWISE ACCESS OR USE THE SERVICES.</li>
      </ol>
    </section>
    <section>
      <h2>IMPORTANT DISCLAIMERS</h2>
      <p>WE DO NOT OFFER OR PROVIDE ANY KIND OF MEDICAL ADVICE OR OTHER HEALTHCARE SERVICE, INCLUDING WITHOUT LIMITATION, ANY TESTING, DIAGNOSIS, EVALUATION, PROCEDURE OR THERAPY RELATED TO MENTAL HEALTH. IF YOU HAVE ANY PROBLEM WITH YOUR HEALTH, YOU SHALL VISIT, CALL OR CONSULT WITH YOUR PHYSICIAN OR OTHER HEALTHCARE PROFESSIONALS.</p>
      <p>THE INFORMATION, PUZZLES AND GAMES PROVIDED WITHIN APP ARE FOR ENTERTAINMENT AND EDUCATIONAL PURPOSES ONLY. WE MAKE NO GUARANTEES CONCERNING THE LEVEL OF SUCCESS YOU MAY EXPERIENCE BY FOLLOWING THE ADVICE AND STRATEGIES CONTAINED IN APP. THE TESTIMONIALS AND EXAMPLES THAT MAY BE PROVIDED ON THE SERVICES ARE EXCEPTIONAL RESULTS, WHICH MAY NOT APPLY TO AN AVERAGE PERSON, AND ARE NOT INTENDED TO REPRESENT OR GUARANTEE THAT ANYONE WILL ACHIEVE THE SAME OR SIMILAR RESULTS.</p>
      <p>IN ADDITION TO ALL OTHER LIMITATIONS AND DISCLAIMERS IN THESE TERMS, THE COMPANY DISCLAIMS ANY LIABILITY OR LOSS IN CONNECTION WITH THE CONTENT PROVIDED ON THE SERVICES.</p>
    </section>
    <section>
      <h2>Profile Registration</h2>
      <ol>
        <li>In order to use the Services, it might be required to register your profile in App or the Website ("Profile") and provide certain information about yourself as prompted by the registration form.</li>
        <li>If you register Profile, you represent and warrant to BrainBloom that: (i) all required registration information you submit is truthful and accurate; (ii) you will maintain the accuracy of such information; and (iii) your use of the Services does not violate any applicable law or regulation or these Terms. Otherwise, the Services may not operate correctly, and we may not be able to contact you with important notices.</li>
        <li>The Services is not intended to be used by individuals under the age of 16. You hereby represent and warrant to BrainBloom that you meet the foregoing qualification. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor, you must have your parent or guardian read and agree to these Terms prior to you using the Services.</li>
        <li>We reserve the right to suspend or terminate your Profile, or your access to the Services, with or without notice to you, in the event that you breach these Terms.</li>
        <li>You are responsible for maintaining the confidentiality of your Profile login information and are fully responsible for all activities that occur under yourProfile. You agree to immediately notify us of any unauthorized use,or suspected unauthorized use of your Profile or any other breach of security. BrainBloom cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</li>
        <li>By using the Services, you agree to receive from BrainBloom certain communications, such as updates on the Services, a periodic e-mail newsletter, or a push notification. You can opt-out of non-essential communications by unsubscribing from the email letter or by adjusting your device push preferences.</li>
      </ol>
    </section>
    <section>
      <h2>Services</h2>
      <ol>
        <li>You agree, represent and warrant that your use of the Services, or any portion thereof, will be consistent with the foregoing license, covenants and restrictions and will neither in fringe nor violate the rights of any other party or breach any contract or legal duty to any other parties. In addition, you agree that you will comply with all applicable laws, regulations and ordinances relating to the Services or your use of it, and you will be solely responsible for your own individual violations of any such laws.</li>
        <li>You are solely responsible for obtaining the equipment and telecommunication services necessary to access the Services, and all fees associated therewith (such as computing devices and Internet service provider and airtime charges).</li>
        <li>We retain the right to implement any changes to the Services (whether to free or paid features) at any time, without notice to you or our liability. You acknowledge that a variety of our actions may impair or prevent you from accessing the Services at certain times and/or in the same way, for limited periods or permanently, and agree that BrainBloom has no responsibility or liability as a result of any such actions or results, including, without limitation, for the deletion of, or failure to make available to you, any content or services.</li>
        <li>Your access to and use of the Services is at your own risk. We will have no responsibility for any harm to your computing system, loss of data, or other harm to you or any third party, that results from your access to or use of the Services, or reliance on any information or advice.</li>
        <li>We have no obligation to provide you with customer support of any kind. However, BrainBloom may provide you with customer support from time to time, at its sole discretion.</li>
      </ol>
    </section>
    <section>
      <h2>Intellectual Property, User Content</h2>
      <ol>
        <li>You acknowledge that all the text, images, marks, logos, compilations (meaning the collection, arrangement and assembly of information), data, other content, software and materials displayed on the Services or used by BrainBloom, excluding any User Content (as defined below), is proprietary to BrainBloom or to third parties.</li>
        <li>BrainBloom expressly reserves all rights, including all intellectual property rights, in all of the foregoing, and except as expressly permitted by these Terms, any use, redistribution, sale, decompilation, reverse engineering, disassembly, translation or other exploitation of them is strictly prohibited. The provision of the Services does not transfer to you or any third party any rights, title or interest in or to such intellectual property rights.</li>
        <li>The information you submit to us as part of your registration, and any data, text and other material that you may submit or post to us ("User Content") remain your intellectual property, and we do not claim any ownership of the copyright or other proprietary rights in such registration information and the User Content. Notwithstanding the foregoing, you agree that BrainBloom may retain copies of all registration information and the User Content and use such information and the User Content as reasonably necessary for or incidental to its operation of the Services and as described in these Terms and the <a href={'/privacy-policy'}>Privacy Policy</a>.</li>
        <li>You grant BrainBloom the non-exclusive, worldwide, royalty-free, transferable, perpetual, irrevocable right to publish, distribute, publicly display and perform the User Content in connection with the Services.</li>
        <li>Subject to these Terms, BrainBloom grants you a non-transferable, non-exclusive, license (without the right to sublicense) to (i) use the Services solely for your personal, non-commercial purposes; (ii) install and use App solely on your own handheld mobile device and solely for your personal, non-commercial purposes.</li>
      </ol>
    </section>
    <section>
      <h2>App Stores, Third Party Ads, Other Users</h2>
      <ol>
        <li>You acknowledge and agree that the availability of App is dependent on the third party from which you received App, e.g., the Apple App Store, GooglePlay Market,and/or other app stores (collectively, "AppStores" and each, an "App Store").</li>
        <li>You agree to pay all fees charged by the App Stores in connection with App. You agree to comply with, and your license to use App is conditioned upon your compliance with, all applicable agreements, terms of use, and other policies of the AppStores.You acknowledge that the App Stores (and their subsidiaries) are a third-party beneficiary of these Terms and will have the right to enforce these Terms.</li>
        <li>The Services may contain links to third party websites or resources and advertisements for third parties (collectively, "Third-Party Ads"). Such Third-Party Ads are not under the control of BrainBloom and we are not responsible for any Third-Party Ads. BrainBloom provides these Third-Party Ads only as a convenience and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Ads. Advertisements and other information provided by Third-Party Ads may not be wholly accurate. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources. When you link to a third-party website, the applicable service provider's terms and policies, including privacy and data gathering practices govern. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any third party. Your transactions and other dealings with Third-Party Ads, including payment and delivery of related goods or services, are solely between you and such merchants or advertisers.</li>
        <li>Each user of the Services is solely responsible for any and all his or her User Content. Because we do not control the User Content, you acknowledge and agree that we are not responsible for any User Content and we make no guarantees regarding the accuracy, currency, suitability, or quality of any User Content, and we assume no responsibility for any User Content. Your interactions with other Services users are solely between you and such users. You agree that BrainBloom will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Services user, we are under no obligation to become involved.</li>
        <li>You hereby release us, our officers, employees, agents and successors from claims, demands any and all losses, damages, rights, claims, and actions of any kind including personal injuries, death, and property damage, that is either directly or indirectly related to or arises from any interactions with or conduct of any App Store, any other Services users, or any Third-Party Ads.</li>
      </ol>
    </section>
    <section>
      <h2>Payments and Refunds</h2>
      <ol>
        <li>
          App is free to download. However, certain features of the Services may be offered for a fee by (1) paying a subscription fee in advance on a recurring interval disclosed to you prior to your purchase; or (2) one-time payment giving you a lifetime access to App (together or separately the “Purchase”). You make the Purchase directly from BrainBloom or through the App Store.
          <br/>
          <br/>
          For the purposes of the lifetime access to App, lifetime constitutes 30 years or until the date BrainBloom ceases to commercially offer the Services, whatever period is shorter. We make no warranties as to the expected duration of the lifetime access and its related Services. You acknowledge and agree that the Services under the lifetime access may change or terminate in the future. You may not assign, transfer, or resell in any way the lifetime access to any other person. We reserve the right to revise the terms of or make changes to the lifetime access by updating these Terms accordingly, provided that we will not shorten the duration of the lifetime access specified above.
        </li>
        <li>To the maximum extent permitted by applicable laws, we may change Purchase fees at any time. We will give you reasonable notice of any such pricing changes by posting the new prices on or through App and/or by sending you an e-mail notification, or in other prominent ways. If you do not wish to pay the new fees, you can cancel the applicable subscription prior to the change going into effect or/and abstain from pre-paying for access to the Services.</li>
        <li>You authorize us and the App Stores to charge the applicable fees to the payment card that you submit.</li>
        <li>By signing up for certain subscriptions, you agree that your subscription may be automatically renewed. Unless you cancel your subscription, you authorize us and the App Stores to charge you for the renewal term. The period of auto-renewal will be the same as your initial subscription period unless otherwise disclosed to you on the Services. The renewal rate will be no more than the rate for the immediately prior subscription period, excluding any promotional and discount pricing, unless we notify you of a rate change prior to your auto-renewal. You must cancel your subscription in accordance with the cancellation procedures disclosed to you for the particular subscription. We will not refund fees that may have accrued to your account and will not prorate fees for a cancelled subscription.</li>
        <li>We may offer a trial subscription for the Services. Trial provides you access to the Services or a part of the Services for a period of time, with details specified when you sign up for the offer. Unless you cancel before the end of the free trial, or unless otherwise stated, your access to the Services will automatically continue and you will be billed the applicable fees for the Services. It is ultimately your responsibility to know when the free trial will end. We reserve the right, in our absolute discretion, to modify or terminate any free trial offer, your access to the Services during the free trial, or any of these terms without notice and with no liability. We reserve the right to limit your ability to take advantage of multiple free trials.</li>
        <li>The Services and your rights to use it expire at the end of the paid period of your subscription. If you do not pay the fees or charges due, we may make reasonable efforts to notify you and resolve the issue; however, we reserve the right to disable or terminate your access to the Services (and may do so without notice).</li>
        <li>Subscriptions purchased via the App Store are subject to such App Store's refund policies. This means we cannot grant refunds. You will have to contact the App Store support.</li>
        <li>You agree that the Purchase is final, that BrainBloom will not refund any transaction once it has been made and that the Purchase cannot be canceled. When you make the Purchase, you acknowledge and agree that all Purchases are non-refundable or exchangeable. Notwithstanding anything to the contrary in the foregoing, BrainBloom will provide refunds and/or Purchase cancellations in cases and to the extent required by mandatory provisions of the applicable law. BrainBloom may also provide refunds at its own discretion and subject to our policies that may be published from time to time.</li>
      </ol>
      <p><u>Note for the EU residents</u>: If you are an EU user, you have the right to withdraw from the agreement for the purchase of digital content without charge and without giving any reason within fourteen (14) days from the date of such agreement conclusion. The withdrawal right does not apply if the performance of the agreement has begun with your prior express consent and your acknowledgment that you thereby lose your right of withdrawal. YOU HEREBY EXPRESSLY CONSENT TO THE IMMEDIATE PERFORMANCE OF THE AGREEMENT AND ACKNOWLEDGE THAT YOU WILL LOSE YOUR RIGHT OF WITHDRAWAL FROM THE AGREEMENT ONCE OUR SERVERS VALIDATE YOUR PURCHASE AND THE APPLICABLE PURCHASE IS SUCCESSFULLY DELIVERED TO YOU. Therefore, you will not be eligible for a refund, unless the digital content is defective.</p>
    </section>
    <section>
      <h2>User Representations and Restrictions</h2>
      <ol>
        <li>By using the Services, you represent and warrant that:
          <ul>
            <li>you have the legal capacity and you agree to comply with these Terms;</li>
            <li>you are not under the age of 16;</li>
            <li>you will not access the Services through automated or non-human means, whether through a bot, script or otherwise;</li>
            <li>you will not use the Services for any illegal or unauthorized purpose;</li>
            <li>you are not located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a "terrorist supporting" country;</li>
            <li>you are not listed on any U.S. government list of prohibited or restricted parties; and</li>
            <li>your use of the Services will not violate any applicable law or regulation.</li>
          </ul>
        </li>
        <li>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to refuse any and all current or future use of the Services (or any portion thereof).</li>
        <li>You may not access or use the Services for any purpose other than that for which we make the Services available. The Service may not be used in connection with any commercial endeavors except those that are specifically authorized or approved by us.</li>
        <li>As a user of the Services, you agree not to:
          <ul>
            <li>systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us;</li>
            <li>make any unauthorized use of the Services;</li>
            <li>make any modification, adaptation, improvement, enhancement, translation, or derivative work from the Services;</li>
            <li>use the Services for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;</li>
            <li>make the Services available over a network or other environment permitting access or use by multiple devices or users at the same time;</li>
            <li>use the Services for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the Services;</li>
            <li>use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the Services;</li>
            <li>circumvent, disable, or otherwise interfere with security-related features of the Services;</li>
            <li>engage in unauthorized framing of or linking to the Services;</li>
            <li>interfere with, disrupt, or create an undue burden on the Service or the networks or services connected to the Services;</li>
            <li>decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services;</li>
            <li>attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services;</li>
            <li>upload or distribute in any way files that contain viruses, worms, trojans, corrupted files, or any other similar software or programs that may damage the operation of another‘s computer;</li>
            <li>use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses theServices, or using or launching any unauthorized script or other software;</li>
            <li>use the Services to send automated queries to any website or to send any unsolicited commercial e-mail;</li>
            <li>disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services;</li>
            <li>use the Services in a manner inconsistent with any applicable laws or regulations; or</li>
            <li>otherwise infringe these Terms.</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h2>Indemnification</h2>
      <p>You hereby agree to indemnify and hold BrainBloom, any of its officers, directors, employees and agents and its affiliated and related entities, harmless from and against any claims, disputes, costs, losses, liabilities, damages, expenses and judgments of any and every kind, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (a) your access to or use of the Services, (b) your User Content, or (c) your violation of these Terms.</p>
    </section>
    <section>
      <h2>Disclaimer of Warranties</h2>
      <p>THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure, or error free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any information or content on the Services. YOUR USE OF THE SERVICES IS AT YOUR OWN RISK.</p>
    </section>
    <section>
      <h2>Limitation of Liability</h2>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER BRAINBLOOM NOR ITS SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT BRAINBLOOM OR ITS SERVICE PROVIDERS HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE JURISDICTION, IN NO EVENT WILL BRAINBLOOM’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO BRAINBLOOM FOR USE OF THE SERVICES OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO BRAINBLOOM AS APPLICABLE.</p>
      <p>THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN BRAINBLOOM AND YOU.</p>
    </section>
    <section>
      <h2>International Use</h2>
      <p>BrainBloom makes no representation that the Services are accessible, appropriate or legally available for use in your jurisdiction, and accessing and using the Services is prohibited from territories where doing so would be illegal. You access the Services at your own initiative and are responsible for compliance with local laws.</p>
    </section>
    <section>
      <h2>Mandatory Binding Arbitration and Class Action Waiver</h2>
      <ol>
        <li>Mandatory Arbitration of Disputes. You and BrainBloom agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, “Disputes”) will be resolved solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding. You and BrainBloom agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of these Terms, and that you and BrainBloom are each waiving the right to a trial by jury or to participate in a class action. This arbitration provision shall survive termination of these Terms.</li>
        <li>Exceptions. As limited exceptions to Section 14: (i) we both may seek to resolve a Dispute in local court if it qualifies; and (ii) we each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual property rights.</li>
        <li>Conducting Arbitration and Arbitration Rules. The arbitration will be conducted by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (the “AAA Rules”) then in effect, except as modified by these Terms. The AAA Rules are available at <a href={'https://www.adr.org/'} target={'_blank'} rel={'noreferrer'}>www.adr.org</a> or by calling <a href={'tel:18007787879'}>1-800-778-7879</a>. A party who wishes to start arbitration must submit a written Demand for Arbitration to AAA and give notice to the other party as specified in the AAA Rules. The AAA provides a form Demand for Arbitration at <a href={'https://www.adr.org/'} target={'_blank'} rel={'noreferrer'}>www.adr.org</a>. Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the interpretation, applicability, enforceability and scope of this arbitration agreement.</li>
        <li>Arbitration Costs. Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules, and we won’t seek to recover the administration and arbitrator fees we are responsible for paying, unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration, we’ll pay all of our attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses to the extent provided under applicable law.</li>
        <li>Injunctive and Declaratory Relief. Except as provided in Section 13.1 above, the arbitrator shall determine all issues of liability on the merits of any claim asserted by either party and may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim. To the extent that you or we prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and not in arbitration. The parties agree that litigation of any issues of public injunctive relief shall be stayed pending the outcome of the merits of any individual claims in arbitration.</li>
        <li>Class Action Waiver. YOU AND BRAINBLOOM AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if the parties’ Dispute is resolved through arbitration, the arbitrator may not consolidate another person’s claims with your claims and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then entire Section 13 shall be null and void.</li>
      </ol>
    </section>
    <section>
      <h2>Governing Law and Venue</h2>
      <ol>
        <li>These Terms shall be governed in accordance with the laws of the Republic of Cyprus (excluding its body of law governing conflicts of law).</li>
        <li>To the extent that any action relating to any dispute hereunder is for whatever reason not submitted to arbitration, each of the parties submits to the exclusive jurisdiction to the courts of the Republic of Cyprus to settle any disputes which may arise out of or in connection with this Terms and that accordingly proceedings must be brought in such courts. The parties irrevocably waive any defenses of improper venue or forum non conveniens.</li>
        <li><u>If you are a resident of the EU:</u>
          <p>Nothing in these Terms shall deprive you of the protection afforded to consumers by the mandatory rules of law of the country in which you live.</p>
          <p>If you have a complaint, please contact us at <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a>. If you feel your complaint is not adequately addressed you may – but are not obliged to – use the Online Dispute Resolution (ODR) platform that you can access through <a href={'http://ec.europa.eu/odr'} target={'_blank'} rel={'noreferrer'}>http://ec.europa.eu/odr</a>. Other than as set out in the Terms, BrainBloom does not participate in any alternative dispute resolution scheme.</p>
          <p>You may bring any dispute which may arise under these Terms to the competent court of your country of habitual residence if this country of habitual residence is an EU Member State, which courts are – with the exclusion of any other court - competent to settle any of such a dispute. BrainBloom shall bring any dispute which may arise under these Terms to the competent court of your country of habitual residence.</p>
          <p>You agree that the Services, Terms, and any dispute between you and BrainBloom shall be governed in all respects by laws of the republic of Cyprus, without regard to choice of law provisions.</p>
        </li>
      </ol>
    </section>
    <section>
      <h2>Miscellaneous</h2>
      <ol>
        <li>No delay or omission by us in exercising any of our rights occurring upon any noncompliance or default by you with respect to these Terms will impair any such right or be construed to be a waiver thereof, and a waiver by BrainBloom of any of the covenants, conditions or agreements to be performed by you will not be construed to be a waiver of any succeeding breach thereof or of any other covenant, condition or agreement hereof contained.</li>
        <li>Subject to Section 13, if any provision of these Terms is found to be invalid or unenforceable, then these Terms will remain in full force and effect and will be reformed to be valid and enforceable while reflecting the intent of the parties to the greatest extent permitted by law.</li>
        <li>Except as otherwise expressly provided herein, these Terms set forth the entire agreement between you and BrainBloom regarding its subject matter, and supersede all prior promises, agreements or representations, whether written or oral, regarding such subject matter.</li>
        <li>We may transfer or assign any and all of our rights and obligations under these Terms to any other person, by any way, including by novation, and by accepting these Terms you give us consent to any such assignment and transfer. You confirm that placing on the Services of a version of these Terms indicating another person as a party to these Terms shall constitute valid notice to you of the transfer of BrainBloom’s rights and obligations under these Terms (unless otherwise is expressly indicated).</li>
        <li>All information communicated on the Services is considered an electronic communication. When you communicate with us through or on the Services or via other forms of electronic media, such as e-mail, you are communicating with us electronically. You agree that we may communicate electronically with you and that such communications, as well as notices, disclosures,agreements, and other communications that we provide to you electronically, are equivalent to communications in writing and shall have the same force and effect as if they were in writing and signed by the party sending the communication. You further acknowledge and agree that by clicking on a button labeled "SUBMIT", "CONTINUE", "REGISTER", "I AGREE" or similar links or buttons, you are submitting a legally binding electronic signature and are entering into a legally binding contract. You acknowledge that your electronic submissions constitute your agreement and intent to be bound by these Terms. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND OTHER RECORDS AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED THROUGH THE SERVICE.</li>
        <li>In no event shall BrainBloom be liable for any failure to comply with these Terms to the extent that such failure arises from factors outside BrainBloom's reasonable control.</li>
      </ol>
    </section>
    <section>
      <h2>Contact</h2>
      <p>If you want to send any notice under these Terms or have any questions regarding the Services, you may contact us at: <a href={'mailto:support@brainbloom.me'}>support@brainbloom.me</a>.</p>
      <p>
        APPLYFT LTD, Parnithos, 9 Flat/Office A, Germasogeia, 4040, Limassol, Cyprus<br/>
        +1 (650) 319-7416<br/>
        support@brainbloom.me<br/>
      </p>
      <p>I HAVE READ THESE TERMS AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.</p>
    </section>
    <p>Last Update: 13 March 2023</p>
  </S.PageWrapper>
);
