import logo from '../logo.svg';
import styled, { keyframes } from 'styled-components';

const anim = keyframes`
  to {
    background-position: 10000px;
  }
`;

const Text = styled.div`
  margin-top: 30px;
  font-size: 60px;
  white-space: nowrap;
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
  background-image: -webkit-linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
  background-image: -moz-linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
  background-image: -ms-linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
  background-repeat: repeat;
  animation: ${anim} 20s linear infinite;
`;

export const Home = () => (
  <div className='App'>
    <header className='App-header'>
      <img src={logo} className='App-logo' alt='logo' />
      <Text>coming soon...</Text>
    </header>
  </div>
);
